import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const PreviewImage = ({ title, img, isFeature }) => {
  return (
    <GatsbyImage
      className={`feature-img ${isFeature ? 'is-feature' : ''} mb-3`}
      aspectratio={16 / 9}
      image={getImage(img)}
      alt={title}
    />
  )
}

PreviewImage.defaultProps = {
  isFeature: false,
}

PreviewImage.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  isFeature: PropTypes.bool,
}

export default PreviewImage
