import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Data
import LayoutServices from '../../../data/layout'

// Components
import FeatureItem from './FeatureItem'

const MappedServices = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            home {
              smallOnly
            }
          }
        }
      }
    `,
  )

  const smallOnly = site?.siteMetadata?.home?.smallOnly

  return smallOnly ? (
    <div className="container">
      <div className="row">
        <div className="col-sm-10 offset-sm-1">
          <div className="d-lg-flex">
            <FeatureItem data={LayoutServices.find(item => item.id === 'small')} />
            <FeatureItem data={LayoutServices.find(item => item.id === 'custom')} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    LayoutServices.map((_, key) => {
      return (
        <React.Fragment key={key}>
          {key % 2 === 0 ? (
            <div className="container">
              <div className="row">
                <div className="col-sm-10 offset-sm-1">
                  <div className="d-lg-flex">
                    <FeatureItem data={LayoutServices[key]} />
                    <FeatureItem data={LayoutServices[key + 1]} />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </React.Fragment>
      )
    })
  )
}

export default MappedServices
