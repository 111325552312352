import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Header = ({ title, slug }) => {
  return (
    <div className="mt-1">
      <Link className="feature-post-link" to={slug}>
        <h4 className="h6">{title}</h4>
      </Link>
    </div>
  )
}

Header.defaultProps = {
  title: 'MISSING TITLE',
  slug: 'MISSING SLUG',
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
}

export default Header
