import React, { useContext, useState, useEffect } from 'react'

// Context
import { ThemeContext } from '../../context/ThemeProvider'

// Components
import MappedServices from './MappedServices'

const Features = () => {
  const { windowWidth } = useContext(ThemeContext)
  const [marginTop, setMarginTop] = useState(0)

  useEffect(() => {
    windowWidth > 991 ? setMarginTop('-80px') : setMarginTop(0)
  }, [windowWidth])

  return (
    <section className="layouts" style={{ marginTop }}>
      <div className="container">
        <div className="row">
          <MappedServices />
        </div>
      </div>
    </section>
  )
}

export default Features
