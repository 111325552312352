import React from 'react'
import PropTypes from 'prop-types'

// React Icons
import { AiOutlineArrowRight } from 'react-icons/ai'

// Components
import BtnOutline from './Buttons/BtnOutline'

const handleBackColor = v => {
  if (typeof v === 'boolean') return v === true ? '#f6f9fc' : '#fffff'
  return v
}

const HomeContainer = ({ backColor, headerName, href, btnText, children }) => {
  return (
    <section style={{ backgroundColor: handleBackColor(backColor) }}>
      <div className="col-md-10 offset-md-1 p-5 pb-0">
        <div className="d-flex align-items-center justify-content-between mb-sm-3 mb-2">
          <h2 className="h3 m-0">{headerName}</h2>
          <BtnOutline href={href} size="s">
            {btnText} <AiOutlineArrowRight />
          </BtnOutline>
        </div>
        {children}
      </div>
    </section>
  )
}

HomeContainer.defaultProps = {
  backColor: false,
  headerName: 'HEADER TITLE MISSING',
}

HomeContainer.propTypes = {
  backColor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  headerName: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default HomeContainer
