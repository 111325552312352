import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import PostPreview from './PostPreview'

const BlogHome = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "blog-post.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        allMdx(
          sort: { fields: frontmatter___date, order: DESC }
          limit: 6
          filter: { fileAbsolutePath: { regex: "/blog/" } }
        ) {
          nodes {
            id
            frontmatter {
              date
              title
              slug
              featureImg {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              keywords
              description
            }
          }
        }
      }
    `,
  )

  const posts = data?.allMdx?.nodes
  const defaultImg = data?.file

  return (
    <div className="pb-lg-5 pt-4">
      <div className="row">
        {posts.map(({ id, frontmatter }) => {
          const { title, slug, date, featureImg } = frontmatter
          let img = defaultImg,
            isFeature = false
          if (featureImg !== null) {
            img = featureImg
            isFeature = true
          }
          return (
            <article key={id} className="col-12 col-sm-6 col-xl-4 mb-4">
              <PostPreview title={title} slug={slug} date={date} img={img} isFeature={isFeature} />
            </article>
          )
        })}
      </div>
    </div>
  )
}

export default BlogHome
