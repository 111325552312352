import React from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'

const btnSize = size => {
  switch (size.trim().toLowerCase()) {
    case 's':
      return ' btn-sm'
    case 'lg':
      return ' btn-lg'
    default:
      return ''
  }
}

const BtnOutline = ({ href, size, children, ...props }) => {
  if (href === '')
    return (
      <button className={`btn btn-outline${btnSize(size)}`} {...props}>
        {children}
      </button>
    )
  return (
    <Link className={`btn btn-outline${btnSize(size)}`} to={href} {...props}>
      {children}
    </Link>
  )
}

BtnOutline.defaultProps = {
  href: '',
  size: '',
}

BtnOutline.propTypes = {
  href: PropTypes.string,
  size: PropTypes.oneOf(['s', 'lg', '']),
}

export default BtnOutline
