import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// Components
import BlogDate from './BlogDate'
import PreviewImage from '../PreviewImage'

const PostPreview = ({ title, slug, date, img, isFeature }) => {
  return (
    <div className="feature-post p-1">
      <Link to={slug}>
        <PreviewImage title={title} img={img} isFeature={isFeature} />
      </Link>
      <div className="px-2 feature-details">
        <div className="d-flex align-items-center mb-2">
          <BlogDate date={date} />
        </div>
        <Link className="feature-post-link" to={slug}>
          <h4 className="h6">{title}</h4>
        </Link>
      </div>
    </div>
  )
}

PostPreview.defaultProps = {
  title: 'MISSING TITLE',
  slug: 'MISSING SLUG',
  date: 'MISSING DATE',
}

PostPreview.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  isFeature: PropTypes.bool,
  cats: PropTypes.array,
}

export default PostPreview
