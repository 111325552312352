import React from 'react'
import PropTypes from 'prop-types'

// Components
import Seo from '../Seo'
import Header from '../Header'
import Footer from '../Footer/'

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location?.pathname === rootPath

  return (
    <>
      <Seo title={title} />
      <div data-is-root-path={isRootPath} className="d-flex flex-column min-vh-100">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  location: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
}

export default Layout
