import React from 'react'
import PropTypes from 'prop-types'

// Utils
import brandObj from '../../../utils/brandObj'

const SizeBrand = ({ width, height, brand }) => {
  const branded = brandObj(brand)
  return (
    <>
      {width !== '' && height !== '' ? (
        <small className="layout-size">
          {`${width}' x ${height}'`}
          &#8194;
        </small>
      ) : (
        ''
      )}
      {branded !== '' && <small className="layout-brand">{branded} layout</small>}
    </>
  )
}

SizeBrand.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  brand: PropTypes.string,
}

export default SizeBrand
