import React from 'react'
import PropTypes from 'prop-types'

const FeatureItem = ({ data }) => {
  const { icon, preName, name, text } = data

  return (
    <div className="layout-item mb-5 mx-4">
      <div className="layout-icon mb-4">{icon}</div>
      <span className="layout-pre">{preName}</span>
      <h4 className="mb-3 mt-0">{name}</h4>
      <p className="mb-4 layout-text">{text}</p>
    </div>
  )
}

FeatureItem.propTypes = {
  data: PropTypes.object.isRequired,
}

export default FeatureItem
