import tracks from '../../data/track.json'

const brandObj = type => {
  return tracks.map(({ id, name }) => {
    return id.toString().trim().toLowerCase() === type.toString().trim().toLowerCase()
      ? name.toString()
      : ''
  })
}

export default brandObj
