import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Context
import { ThemeContext } from '../context/ThemeProvider'

const Hero = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            description
          }
        }
      }
    `,
  )

  const description = site?.siteMetadata?.description
  const videoId = `M0NiEOCKBaI`
  const { windowWidth } = useContext(ThemeContext)

  return (
    <>
      {windowWidth > 991 ? (
        <section className="hero-video">
          <div className="ratio ratio-16x9">
            <iframe
              type="text/html"
              src={`https://youtube.com/embed/${videoId}?rel=1&modestbranding=1&controls=0&autoplay=1&cc_load_policy=0&mute=1&iv_load_policy=3&fs=0&enablejsapi=1&disablekb=1&loop=1&playlist=${videoId}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              title="Embedded YouTube video"
            />
          </div>
          <div className="overlay-text px-5">
            <h1>{description}</h1>
          </div>
        </section>
      ) : (
        <section className="hero mt-3 mb-5 px-4">
          <h1>{description}</h1>
        </section>
      )}
    </>
  )
}

export default Hero
