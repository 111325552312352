import React from 'react'
import PropTypes from 'prop-types'

const BlogDate = ({ date }) => {
  const d = new Date(date)
  return (
    <div className="feature-blog-date">
      <small>
        {d.toLocaleString('en-us', { weekday: 'short' })},{' '}
        {d.toLocaleString('default', { month: 'short' })} {d.getDate()}
      </small>
    </div>
  )
}

BlogDate.propTypes = {
  date: PropTypes.string.isRequired,
}

export default BlogDate
