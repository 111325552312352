import React from 'react'
import PropTypes from 'prop-types'

// Components
import Layout from '../components/Layout/'
import Hero from '../components/Hero'
import Features from '../components/Features'
import Newsletter from '../components/Newsletter'
import HomeContainer from '../components/HomeContainer'
import SmallHome from '../components/Track/SmallHome'
import BlogHome from '../components/Blog/BlogHome'

const HomePage = ({ location }) => {
  return (
    <Layout location={location} title="Home">
      <Hero />
      <Features />
      <HomeContainer
        backColor={true}
        headerName="Small Layouts"
        href="small-layouts"
        btnText={`More Plans`}
      >
        <SmallHome />
      </HomeContainer>
      <HomeContainer headerName="Trainmaster" href="blog" btnText={`Read Blog`}>
        <BlogHome />
      </HomeContainer>
      <Newsletter />
    </Layout>
  )
}

HomePage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default HomePage
