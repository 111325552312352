import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import TrackPreview from './TrackPreview'

const SmallHome = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "blog-post.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        allMdx(
          sort: { fields: frontmatter___date, order: ASC }
          limit: 6
          filter: { fileAbsolutePath: { regex: "/smallLayout/" } }
        ) {
          nodes {
            id
            frontmatter {
              title
              slug
              width
              height
              trackBrand
              featureImg {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
          }
        }
      }
    `,
  )

  const posts = data?.allMdx?.nodes
  const defaultImg = data?.file

  return (
    <div className="pb-lg-5 pt-4">
      <div className="row">
        {posts.map(({ id, frontmatter }) => {
          const { title, slug, featureImg, trackBrand, width, height } = frontmatter
          let img = defaultImg,
            isFeature = false
          if (featureImg !== null) {
            img = featureImg
            isFeature = true
          }
          return (
            <article key={id} className="col-12 col-sm-6 col-xl-4 mb-4">
              <TrackPreview
                title={title}
                slug={slug}
                img={img}
                isFeature={isFeature}
                brand={trackBrand}
                width={width}
                height={height}
              />
            </article>
          )
        })}
      </div>
    </div>
  )
}

export default SmallHome
