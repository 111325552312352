import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

// Components
import TrackImage from '../TrackImage'
import SizeBrand from './SizeBrand'
import Header from './Header'

const SinglePreview = ({ title, slug, img, isFeature, brand, width, height }) => {
  return (
    <div className="small-layout-post p-1">
      <Link to={slug}>
        <TrackImage title={title} img={img} isFeature={isFeature} />
      </Link>
      <div className="px-2 feature-details">
        <SizeBrand width={width} height={height} brand={brand} />
        <Header title={title} slug={slug} />
      </div>
    </div>
  )
}

SinglePreview.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  isFeature: PropTypes.bool,
  brand: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default SinglePreview
