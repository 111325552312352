import React from 'react'

// Components
import BoxCar from '../src/components/Icons/BoxCar'

const LayoutServices = [
  {
    id: 'small',
    icon: <BoxCar car="s" />,
    preName: `Great for new enthusiasts`,
    name: `Small Sized Layouts`,
    text: `Explore our selection of stunning layouts made to fit small places. Flaunting your passion for train model collectibles should be easy and convenient.`,
    href: `/small-layouts`,
    hrefText: `link`,
  },
  {
    id: 'medium',
    icon: <BoxCar car="m" />,
    preName: `Perfect for the growing collector`,
    name: `Medium Sized Layouts`,
    text: `Medium-sized layouts are fun, straightforward sets that can be expanded on overtime to make the most out of your play area.`,
    href: `/medium-layouts`,
    hrefText: `link`,
  },
  {
    id: 'large',
    icon: <BoxCar car="l" />,
    preName: `Designed for passionate collectors`,
    name: `Large Sized Layouts`,
    text: `If you benefit from a generous space, our large-sized layouts are perfect to showcase your love of model railroads in style. Building your giant display has never been easier.`,
    href: `/large-layouts`,
    hrefText: `link`,
  },
  {
    id: 'custom',
    icon: <BoxCar car="c" />,
    preName: `Layouts to fit your space perfectly`,
    name: `Custom Sized Layouts`,
    text: `Do you have a specific space to dedicate to your model train passion? Order a custom-sized layout plan from us and get started.`,
    href: `/custom-layouts`,
    hrefText: `link`,
  },
]

export default LayoutServices
