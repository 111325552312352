import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const TrackImage = ({ title, img, isFeature }) => {
  return (
    <GatsbyImage
      className={`small-layout-img ${isFeature ? 'is-feature ' : ''}mb-1`}
      image={getImage(img)}
      alt={title}
    />
  )
}
TrackImage.defaultProps = {
  isFeature: false,
}

TrackImage.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.object.isRequired,
  isFeature: PropTypes.bool,
}

export default TrackImage
